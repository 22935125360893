import React, { FC } from 'react';
import _ from 'lodash';
import { Checkbox, Form, Input, InputNumber, Select } from 'antd';
import { useSelector } from 'react-redux';
import { ObjectView } from '../../ObjectView';
import GoodShopPropertyValueMapList from '../../../legacy/components/goodModel/GoodShopPropertyValueMapList';
import { getCrudItems } from '../../../store/Crud/selectors';
import {
    filterType,
    filterTypeText,
    formItemLayout,
    goodPropertyType,
    goodPropertyTypeText,
    okei,
    priceType,
    priceTypeText,
    resource,
} from '../../../utils/constants';
import {
    ICategory,
    IDeliveryIntegration,
    IGoodProperty,
    IModel,
    IPaymentIntegration,
    IPrice,
    IShopAddress,
} from '../../../store/Crud/types';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import GoodPropertyValueList from '../../../pages/GoodModelEditPage/components/GoodPropertyValueList';
import { MODEL_FIELDS } from '../../../store/Crud/types/model';
import { SHOP_TYPE, SHOP_TYPE_LABEL } from '../../../store/Crud/types/shop';
import BrandFormField from './BrandFormField';
import ShopFormField from './ShopFormField';
import HoursFormField from './HoursFormField';
import TagsFormField from './TagsFormField';

interface IRenderFormItem {
    fieldName: string;
    value: any;
    subjectName?: string;
    item: any;
    fields: { [key: string]: string };
    disabledFields: { [key: string]: string };
    requiredFields?: string[];
    hiddenFields?: string[];

    currentItem: { [key: string]: string };
}

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

const RenderFormItem: FC<IRenderFormItem> = ({
    fieldName,
    value,
    subjectName,
    item,
    fields,
    disabledFields,
    requiredFields,
    hiddenFields,
    ...props
}) => {
    const categories: ICategory[] = useSelector(getCrudItems(resource.CATEGORY)) as any;
    const deliveryIntegrations: IDeliveryIntegration[] = useSelector(
        getCrudItems(resource.DELIVERY_INTEGRATION),
    ) as any;
    const paymentIntegrations: IPaymentIntegration[] = useSelector(getCrudItems(resource.PAYMENT_INTEGRATION)) as any;
    const shopAddresses: IShopAddress[] = useSelector(getCrudItems(resource.SHOP_ADDRESS)) as any;
    const models: IModel[] = useSelector(getCrudItems(resource.MODEL)) as any;
    const goodProperties: IGoodProperty[] = useSelector(getCrudItems(resource.MODEL_PROPERTY)) as any;
    const prices: IPrice[] = useSelector(getCrudItems(resource.PRICE)) as any;

    const disabled = !_.has(fields, fieldName) || _.has(disabledFields, fieldName);

    const required = requiredFields?.includes(fieldName);
    const isHidden = hiddenFields?.includes(fieldName);

    if (isHidden) {
        return null;
    }

    const formItemProps = {
        ...formItemLayout,
        key: fieldName,
        name: fieldName,
        label: t(fieldName as enumFieldName),
        initialValue: value,
    };

    switch (fieldName) {
        case 'profile':
        case 'shopAddress':
        case 'deliveryAddress':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <ObjectView object={value} />
                </FormItem>
            );
        case 'mapping':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <GoodShopPropertyValueMapList goodShopProperty={item} />
                </FormItem>
            );
        case 'values':
            return (
                <FormItem {...formItemProps}>
                    <GoodPropertyValueList />
                </FormItem>
            );

        case 'parentModelId':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select allowClear>
                        {models
                            ?.filter(
                                (model) =>
                                    !props?.currentItem ||
                                    String(props?.currentItem?.id) !== String(model[MODEL_FIELDS.id]),
                            )
                            .map((category) => (
                                <Option key={category.id} value={category.id}>
                                    {'\u00A0\u00A0'.repeat(category.path.split('.').length - 1)}
                                    {category.title}
                                </Option>
                            ))}
                    </Select>
                </FormItem>
            );
        case 'parentId':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select allowClear>
                        {categories
                            ?.filter(
                                (category) =>
                                    !props.currentItem ||
                                    category.path.split('.').indexOf(`${props.currentItem.id}`) === -1,
                            )
                            .map((category) => (
                                <Option key={category.id} value={category.id}>
                                    {'\u00A0\u00A0'.repeat(category.path.split('.').length - 1)}
                                    {category.title}
                                </Option>
                            ))}
                    </Select>
                </FormItem>
            );
        case 'modelId':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select allowClear disabled={disabled}>
                        {models?.map((goodModel) => (
                            <Option key={goodModel.id} value={goodModel.id}>
                                {goodModel.title}
                            </Option>
                        ))}
                    </Select>
                </FormItem>
            );
        case 'modelIds':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select allowClear mode="multiple">
                        {models?.map((model) => (
                            <Option key={model.id} value={model.id}>
                                {model.title}
                            </Option>
                        ))}
                    </Select>
                </FormItem>
            );
        case 'modelPropertyId':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select allowClear>
                        {models?.map((goodModel) => (
                            <OptGroup key={goodModel.id} label={goodModel.title}>
                                {goodProperties
                                    ?.filter((itemGoodProperty) => itemGoodProperty.modelId === goodModel.id)
                                    .map((goodProperty) => (
                                        <Option key={goodProperty.id} value={goodProperty.id}>
                                            #{goodProperty.id} {goodProperty.title}
                                        </Option>
                                    ))}
                            </OptGroup>
                        ))}
                    </Select>
                </FormItem>
            );
        case 'categoryId':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select allowClear>
                        {categories?.map((category) => (
                            <Option key={category.id} value={category.id}>
                                {'\u00A0\u00A0'.repeat(category.path.split('.').length - 1)}
                                {category.title}
                            </Option>
                        ))}
                    </Select>
                </FormItem>
            );
        case 'categoryIds':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select allowClear mode="multiple">
                        {categories?.map((category) => (
                            <Option key={category.id} value={category.id}>
                                {'\u00A0\u00A0'.repeat(category.path.split('.').length - 1)}
                                {category.title}
                            </Option>
                        ))}
                    </Select>
                </FormItem>
            );
        case 'shopAddressId':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select allowClear>
                        {shopAddresses?.map((address) => (
                            <Option key={address.id} value={address.id}>
                                {address.title}
                            </Option>
                        ))}
                        <Option key={null} value={null}>
                            Не задан
                        </Option>
                    </Select>
                </FormItem>
            );
        case 'priceIds':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select mode="multiple">
                        {prices?.map((price) => (
                            <Option key={price.id} value={price.id}>
                                {price.title}
                            </Option>
                        ))}
                    </Select>
                </FormItem>
            );
        case 'phones':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select mode="tags" />
                </FormItem>
            );
        case 'defaultPriceId':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select allowClear>
                        {prices?.map((price) => (
                            <Option key={price.id} value={price.id}>
                                {price.title}
                            </Option>
                        ))}
                    </Select>
                </FormItem>
            );
        case 'unitCode':
            return (
                <FormItem rules={[{ required }]} {...formItemProps} initialValue={value}>
                    <Select
                        options={okei}
                        showSearch
                        filterOption={(inputValue, option) =>
                            option?.label.toUpperCase().indexOf(`${inputValue}`.toUpperCase()) !== -1
                        }
                    />
                </FormItem>
            );

        case 'mode':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select allowClear>
                        <Option key="public" value="public">
                            Без регистрации
                        </Option>
                        <Option key="private" value="private">
                            Нужна регистрация
                        </Option>
                    </Select>
                </FormItem>
            );
        case 'priceMode':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select allowClear>
                        <Option key="additional_order" value="additional_order">
                            По запросу (дополнительный заказ на товары без цены)
                        </Option>
                        <Option key="partner_price" value="partner_price">
                            Цены для партнеров по ИНН
                        </Option>
                        <Option key="address_price" value="address_price">
                            Разные цены на разных складах
                        </Option>
                    </Select>
                </FormItem>
            );
        case 'stockMode':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select allowClear>
                        <Option key="factory" value="factory">
                            Производство
                        </Option>
                        <Option key="order" value="order">
                            Под заказ
                        </Option>
                        <Option key="stock" value="stock">
                            Склад
                        </Option>
                    </Select>
                </FormItem>
            );
        case 'filterTypes':
            return (
                <FormItem rules={[{ required }]} {...formItemProps} initialValue={value || []}>
                    <Select allowClear mode="multiple">
                        <Option key={filterType.IN} value={filterType.IN}>
                            {filterTypeText[filterType.IN]}
                        </Option>
                        <Option key={filterType.EQ} value={filterType.EQ}>
                            {filterTypeText[filterType.EQ]}
                        </Option>
                        <Option key={filterType.OVERLAP} value={filterType.OVERLAP}>
                            {filterTypeText[filterType.OVERLAP]}
                        </Option>
                        <Option key={filterType.NE} value={filterType.NE}>
                            {filterTypeText[filterType.NE]}
                        </Option>
                    </Select>
                </FormItem>
            );
        case 'deliveryIntegrationId':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select allowClear>
                        {deliveryIntegrations?.map((deliveryIntegration) => (
                            <Option key={deliveryIntegration.id} value={deliveryIntegration.id}>
                                {deliveryIntegration.title}
                            </Option>
                        ))}
                    </Select>
                </FormItem>
            );
        case 'paymentIntegrationId':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select allowClear>
                        {paymentIntegrations?.map((paymentIntegration) => (
                            <Option key={paymentIntegration.id} value={paymentIntegration.id}>
                                {paymentIntegration.title}
                            </Option>
                        ))}
                    </Select>
                </FormItem>
            );
        case 'nds':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select allowClear>
                        <Option key="20" value={20}>
                            20%
                        </Option>
                        <Option key="10" value={10}>
                            10%
                        </Option>
                        <Option key="0" value={0}>
                            0%
                        </Option>
                        <Option key="-1" value={-1}>
                            не облагается
                        </Option>
                    </Select>
                </FormItem>
            );

        case 'pickupPoint':
        case 'filled':
        case 'active':
        case 'invoiceBoxTestMode':
        case 'multiple':
        case 'public':
            return (
                <FormItem rules={[{ required }]} {...formItemProps} valuePropName="checked">
                    <Checkbox />
                </FormItem>
            );
        case 'type':
            switch (subjectName) {
                case 'dictionary':
                case 'file':
                    return null;
                case 'goodModel':
                    return (
                        <FormItem rules={[{ required }]} {...formItemProps}>
                            <Select allowClear={false}>
                                <Option key={goodPropertyType.NUMBER} value={goodPropertyType.NUMBER}>
                                    {goodPropertyTypeText[goodPropertyType.NUMBER]}
                                </Option>
                                <Option key={goodPropertyType.ENUM} value={goodPropertyType.ENUM}>
                                    {goodPropertyTypeText[goodPropertyType.ENUM]}
                                </Option>
                                <Option key={goodPropertyType.STRING} value={goodPropertyType.STRING}>
                                    {goodPropertyTypeText[goodPropertyType.STRING]}
                                </Option>
                                <Option key={goodPropertyType.DICTIONARY} value={goodPropertyType.DICTIONARY}>
                                    {goodPropertyTypeText[goodPropertyType.DICTIONARY]}
                                </Option>
                            </Select>
                        </FormItem>
                    );
                case 'price':
                    return (
                        <FormItem rules={[{ required }]} {...formItemProps}>
                            <Select allowClear>
                                <Option key={priceType.PUBLIC} value={priceType.PUBLIC}>
                                    {priceTypeText[priceType.PUBLIC]}
                                </Option>
                                <Option key={priceType.PRIVATE} value={priceType.PRIVATE}>
                                    {priceTypeText[priceType.PRIVATE]}
                                </Option>
                                <Option key={priceType.MIN_COUNT} value={priceType.MIN_COUNT}>
                                    {priceTypeText[priceType.MIN_COUNT]}
                                </Option>
                            </Select>
                        </FormItem>
                    );
                default:
                    return (
                        <FormItem rules={[{ required }]} {...formItemProps}>
                            <Select allowClear disabled={disabled}>
                                {Object.values(SHOP_TYPE).map((shopType) => (
                                    <Option key={shopType} value={shopType}>
                                        {SHOP_TYPE_LABEL[shopType]}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                    );
            }

        case 'description':
        case 'deliveryInfo':
        case 'aliasIds':
        case 'content':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Input.TextArea disabled={disabled} />
                </FormItem>
            );
        case 'lat':
        case 'lon':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <InputNumber type="number" disabled={disabled} style={{ width: '100%' }} />
                </FormItem>
            );
        case 'hours':
            return <HoursFormField required={required} disabled={disabled} formItemProps={formItemProps} />;
        case 'tags':
            return <TagsFormField required={required} disabled={disabled} formItemProps={formItemProps} />;
        case 'fields':
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Select mode="tags" style={{ width: '100%' }} />
                </FormItem>
            );
        case 'shopId':
            return <ShopFormField required={required} disabled={disabled} formItemProps={formItemProps} />;
        case 'brandId':
            return <BrandFormField required={required} disabled={disabled} formItemProps={formItemProps} />;
        default:
            if (_.isObject(value)) {
                return null;
            }
            return (
                <FormItem rules={[{ required }]} {...formItemProps}>
                    <Input disabled={disabled} />
                </FormItem>
            );
    }
};

export default RenderFormItem;
