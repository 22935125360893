import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Checkbox, Empty, Input, Space, Table } from 'antd';
import { resource } from '../../utils/constants';
import GoodImg from '../../components/GoodImg';
import GoodPrices from '../../pages/GoodListPage/components/GoodPrices';
import { find } from '../actions/client';
import Linker from '../../components/Linker';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { t } from '../../utils/helpers/formatFieldsLabels';
import { getCrudItems } from '../../store/Crud/selectors';

const GoodList = ({ shopId, ...props }) => {
    const [query, setQuery] = useState('');
    const [queryRaw, setQueryRaw] = useState('');
    const [onlyActive, setOnlyActive] = useState(true);
    const [onlyUnfilled, setOnlyUnfilled] = useState(false);
    const [timer, setTimer] = useState(null);

    const [pagination, setPagination] = useState({ pageSize: 10, current: 1, hideOnSinglePage: true });
    const [items, setItems] = useState([]);
    const [order, setOrder] = useState(['title', 'ascend']);

    const categories = useSelector(getCrudItems(resource.CATEGORY));

    const prepareSortOrder = (sortOrder) => {
        const order = {};
        order[sortOrder[0]] = sortOrder[1] === 'ascend' ? 'asc' : 'desc';

        return order;
    };

    const fetchGoods = useCallback(() => {
        find(resource.GOOD, {
            shopId,
            _page: pagination.current,
            _pageSize: pagination.pageSize,
            _query: query,
            _order: prepareSortOrder(order),
            active: onlyActive && true,
            filled: onlyUnfilled ? false : undefined,
        }).then(([data, metaData]) => {
            setItems(data);
            setPagination({ ...pagination, total: metaData.totalCount });
        });
    }, [shopId, pagination.pageSize, pagination.current, query, onlyActive, onlyUnfilled, order]);

    useEffect(() => {
        fetchGoods();
    }, [fetchGoods]);

    const columns = [
        {
            title: t('id'),
            dataIndex: 'id',
            key: 'id',
            width: '100px',
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: t('externalId'),
            dataIndex: 'externalId',
            key: 'externalId',
            width: '110px',
        },
        {
            title: t('title'),
            dataIndex: 'title',
            key: 'title1',
            width: '10%',
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'ascend',
        },
        {
            title: t('imagePath'),
            dataIndex: 'imagePath',
            key: 'title',
            width: '10%',
            render(imagePath, item) {
                return <GoodImg funcAfterUpload={fetchGoods} item={item} />;
            },
        },
        {
            title: t('categoryId'),
            dataIndex: 'categoryId',
            key: 'categoryId',
            width: '10%',
            render: (categoryId, item) => {
                const category = categories.find((elem) => elem.id === categoryId);
                return category && category.title;
            },
        },
        {
            title: t('stock'),
            dataIndex: 'stock',
            key: 'stock',
            width: '150px',
        },
        {
            title: t('active'),
            dataIndex: 'active',
            key: 'active',
            width: '150px',
            render: (active) => (active ? 'Да' : 'Нет'),
        },
        {
            title: t('unit'),
            dataIndex: 'unit',
            key: 'unit',
            width: '100px',
        },
        {
            title: t('nds'),
            dataIndex: 'nds',
            key: 'nds',
            width: '100px',
        },
        {
            title: t('prices'),
            dataIndex: 'prices',
            key: 'prices',
            width: '400px',
            render: (prices) => <GoodPrices items={prices} />,
        },
    ];

    const onQueryChange = (changedQuery) => {
        setQueryRaw(changedQuery);
        clearTimeout(timer);
        setTimer(setTimeout(() => setQuery(changedQuery), 1000));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        if (sorter.order) {
            setOrder([sorter.field, sorter.order]);
        } else {
            setOrder(['title', 'ascend']);
        }
    };

    return (
        <div>
            <Space style={{ paddingBottom: '20px' }}>
                <Linker type={enumTypesLinks.good} itemId="new">
                    Создать
                </Linker>
                <Input
                    className="good-search-input"
                    placeholder="Поиск"
                    enterbutton="Поиск"
                    value={queryRaw}
                    onChange={(e) => onQueryChange(e.target.value)}
                />
                Только активные
                <Checkbox checked={onlyActive} onChange={(e) => setOnlyActive(e.target.checked)} />
                Только незаполненные
                <Checkbox checked={onlyUnfilled} onChange={(e) => setOnlyUnfilled(e.target.checked)} />
            </Space>
            {!pagination.total ? (
                <Empty />
            ) : (
                <Table
                    pagination={pagination}
                    rowKey="id"
                    dataSource={items}
                    columns={columns}
                    onChange={handleTableChange}
                    onRow={(record) => ({
                        onClick: () => props.onClick(record),
                    })}
                />
            )}
        </div>
    );
};

export default connect(
    (state) => ({
        //     items: state.goods.items,
    }),
    {
        find,
    },
)(GoodList);
