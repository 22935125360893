import React, { FC } from 'react';
import { Button, Col, Row } from 'antd';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import ShopImg from './ShopImg';
import { create, update } from '../../../store/Crud';
import { resource } from '../../../utils/constants';
import ItemEdit from '../../../components/ItemEdit';
import { IShop, SHOP_FIELDS } from '../../../store/Crud/types';
import { createLink } from '../../../components/Linker/PathCreators';
import { enumTypesLinks } from '../../../components/Linker/typesLinks';
import { setCurrentShopId } from '../../../store/System';
import { useAppDispatch } from '../../../store';
import ShopImages from './ShopImages';

interface IShopSetting {
    isAdmin: boolean;
    isNewShop: boolean;
    item: IShop;
}

const ShopSetting: FC<IShopSetting> = ({ isAdmin, isNewShop, item }) => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const fieldsNewShop = { [SHOP_FIELDS.title]: '', [SHOP_FIELDS.description]: '', [SHOP_FIELDS.type]: '' };

    const fields = isNewShop
        ? fieldsNewShop
        : {
              [SHOP_FIELDS.title]: '',
              [SHOP_FIELDS.description]: '',
              [SHOP_FIELDS.lat]: '',
              [SHOP_FIELDS.lon]: '',
              [SHOP_FIELDS.address]: '',
              [SHOP_FIELDS.hours]: undefined,
              [SHOP_FIELDS.minOrderSum]: '',
              [SHOP_FIELDS.notificationEmail]: '',
              [SHOP_FIELDS.brandId]: '',
              [SHOP_FIELDS.yandexMetrikaId]: '',
              [SHOP_FIELDS.jivositeId]: '',
              [SHOP_FIELDS.deliveryInfo]: '',
              [SHOP_FIELDS.seoTitle]: '',
              [SHOP_FIELDS.seoDescription]: '',
              [SHOP_FIELDS.seoKeywords]: '',
              [SHOP_FIELDS.categoryId]: '',
              [SHOP_FIELDS.modelId]: '',
              [SHOP_FIELDS.modelIds]: '',
          };

    const fieldsAdmin = isNewShop
        ? fieldsNewShop
        : {
              [SHOP_FIELDS.title]: '',
              [SHOP_FIELDS.alias]: '',
              [SHOP_FIELDS.description]: '',
              [SHOP_FIELDS.lat]: '',
              [SHOP_FIELDS.lon]: '',
              [SHOP_FIELDS.address]: '',
              [SHOP_FIELDS.hours]: undefined,
              [SHOP_FIELDS.defaultPriceId]: '',
              [SHOP_FIELDS.minOrderSum]: '',
              [SHOP_FIELDS.shopUrl]: '',
              [SHOP_FIELDS.notificationEmail]: '',
              [SHOP_FIELDS.brandId]: '',
              [SHOP_FIELDS.stockMode]: '',
              [SHOP_FIELDS.priceMode]: '',
              [SHOP_FIELDS.mode]: '',
              [SHOP_FIELDS.yandexMetrikaId]: '',
              [SHOP_FIELDS.jivositeId]: '',
              [SHOP_FIELDS.deliveryInfo]: '',
              [SHOP_FIELDS.type]: '',
              [SHOP_FIELDS.seoTitle]: '',
              [SHOP_FIELDS.seoDescription]: '',
              [SHOP_FIELDS.seoKeywords]: '',
              [SHOP_FIELDS.categoryId]: '',
              [SHOP_FIELDS.modelId]: '',
              [SHOP_FIELDS.modelIds]: [],
          };

    const requiredFields = [SHOP_FIELDS.title];

    const onSave = async (currentShop: IShop) => {
        const resultShop = {
            ...currentShop,
            [SHOP_FIELDS.modelId]: currentShop?.[SHOP_FIELDS.modelId] || null,
            [SHOP_FIELDS.minOrderSum]: Number(currentShop.minOrderSum),
            [SHOP_FIELDS.lat]: currentShop.lat || undefined,
            [SHOP_FIELDS.lon]: currentShop.lon || undefined,
            [SHOP_FIELDS.brandId]: currentShop.brandId || null,
            [SHOP_FIELDS.modelIds]: currentShop.modelIds || [],
            [SHOP_FIELDS.categoryId]: !currentShop.categoryId ? null : Number(currentShop.categoryId),
            [SHOP_FIELDS.yandexMetrikaId]: _.isEmpty(currentShop.yandexMetrikaId)
                ? null
                : Number(currentShop.yandexMetrikaId),
            [SHOP_FIELDS.defaultPriceId]: !currentShop.defaultPriceId ? null : currentShop.defaultPriceId,
        };

        if (!isNewShop) {
            await dispatch(update(resource.SHOP, resultShop.id.toString(), resultShop)());
        } else {
            const { payload } = await dispatch(create(resource.SHOP, currentShop)());
            if (payload?.data?.id) {
                history.push(createLink(enumTypesLinks.shop, payload.data.id));
                await dispatch(setCurrentShopId(payload?.data?.id));
            }
        }
    };

    return (
        <div>
            {!isNewShop && (
                <>
                    <Row align="middle" gutter={4} style={{ marginBottom: 10 }}>
                        <Col>
                            <>
                                <ShopImg item={item} />
                                {item.imagePath && (
                                    <Button
                                        onClick={() =>
                                            dispatch(update(resource.SHOP, String(item.id), { imageId: null })())
                                        }
                                        style={{ display: 'inline-block', margin: '10px' }}
                                    >
                                        Удалить изображение
                                    </Button>
                                )}
                            </>
                        </Col>
                        {isAdmin && (
                            <Col>
                                <ShopImg isOgImage item={item} />
                                {item.ogImagePath && (
                                    <Button
                                        onClick={() =>
                                            dispatch(update(resource.SHOP, String(item.id), { ogImageId: null })())
                                        }
                                        style={{ display: 'inline-block', margin: '10px' }}
                                    >
                                        Удалить изображение
                                    </Button>
                                )}
                            </Col>
                        )}
                        <Col>
                            <ShopImages shop={item} />
                        </Col>
                    </Row>
                </>
            )}
            <ItemEdit
                requiredFields={requiredFields}
                item={item}
                fields={isAdmin ? fieldsAdmin : fields}
                onSave={onSave}
            />
        </div>
    );
};

export default ShopSetting;
