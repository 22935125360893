import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { formItemLayout, resource, tailFormItemLayout } from '../../../utils/constants';
import { useAppDispatch } from '../../../store';
import { create, find, get, update } from '../../../store/Crud';
import { getShopId } from '../../../store/System/selectors';
import { getCrudItem, getCrudItems } from '../../../store/Crud/selectors';
import Select from '../../../components/ItemEdit/components/Select';
import ErrorMessage from '../../../components/ItemEdit/components/ErrorMessage';
import { createShopNotificationLink } from '../../../components/Linker/PathCreators';
import { INotificationIntegration, INTEGRATION_FIELDS } from '../../../store/Crud/types/integrations';
import {
    enumEventShopNotification,
    enumHandlerNameShopNotification,
    localeEventShopNotification,
    localeHandlerName,
} from '../../../store/Crud/types/notification';

interface IShopNotificationEdit {
    itemId: string;
}

const ShopNotificationEdit: FC<IShopNotificationEdit> = ({ itemId }) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const shopId: string = useSelector(getShopId) as any;
    const notificationIntegrations: INotificationIntegration[] = useSelector(
        getCrudItems(resource.NOTIFICATION_INTEGRATION),
    ) as any;
    const shopNotification = useSelector(getCrudItem(resource.SHOP_NOTIFICATION));

    const [currentId, setCurrentId] = useState<string>();
    const [notificationIntegrationId, setNotificationIntegrationId] = useState<string>();
    const [handlerName, setHandlerName] = useState<string>();
    const [error, setError] = useState<{ code: string }>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSave = async (submitData: INotificationIntegration) => {
        const item = {
            ...submitData,
        };
        setIsSubmitting(true);
        if (item.id) {
            const { payload } = await dispatch(update(resource.SHOP_NOTIFICATION, item.id, item)());
            if (payload?.error?.code) {
                setError(payload?.error);
            } else {
                setError(undefined);
            }
        } else {
            const { payload } = await dispatch(create(resource.SHOP_NOTIFICATION, { ...item, shopId })());
            if (payload.data) {
                history.push(createShopNotificationLink(shopId, payload.data.id));
            }
            if (payload?.error?.code) {
                setError(payload?.error);
            }
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        if (currentId !== 'new') {
            form.resetFields();
        }

        if (currentId !== itemId) {
            if (itemId !== 'new') {
                dispatch(get(resource.SHOP_NOTIFICATION, itemId, { shopId })());
            }
            dispatch(find(resource.NOTIFICATION_INTEGRATION)());
            setCurrentId(itemId);
        }
    }, [currentId, dispatch, form, itemId, shopId]);

    useEffect(() => {
        if (shopNotification) {
            setHandlerName(shopNotification?.handlerName);
        }
    }, [shopNotification]);

    const currentShopNotification =
        shopNotification && String(shopNotification.id) === itemId
            ? shopNotification
            : {
                  shopId,
                  description: '',
              };

    const currentDeliveryIntegrationId = notificationIntegrationId || currentShopNotification.notificationIntegrationId;
    const notificationIntegration = notificationIntegrations.find(
        (itemNotificationIntegration) => itemNotificationIntegration.id === currentDeliveryIntegrationId,
    );

    if ((itemId !== 'new' && String(shopNotification?.id) === itemId) || itemId === 'new')
        return (
            <Form onFinish={onSave} form={form} initialValues={currentShopNotification}>
                <Form.Item name="id" label={t(enumFieldName.id)} {...formItemLayout}>
                    <Input disabled />
                </Form.Item>
                <Form.Item name="title" label={t(enumFieldName.title)} rules={[{ required: true }]} {...formItemLayout}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label={t(enumFieldName.description)}
                    rules={[{ required: true }]}
                    {...formItemLayout}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    rules={[{ required: true }]}
                    name="notificationIntegrationId"
                    label="Способ оповещения"
                >
                    <Select
                        onChange={(value: string) => {
                            setNotificationIntegrationId(value);
                            const currentNotificationIntegration = notificationIntegrations?.find(
                                (itemNotificationIntegration) =>
                                    itemNotificationIntegration?.[INTEGRATION_FIELDS.id] === value,
                            );

                            if (
                                handlerName &&
                                !currentNotificationIntegration?.[INTEGRATION_FIELDS.handlerNames]?.includes(
                                    handlerName,
                                )
                            ) {
                                form.setFieldsValue({ handlerName: undefined });
                            }
                        }}
                        items={notificationIntegrations}
                    />
                </Form.Item>
                <Form.Item {...formItemLayout} rules={[{ required: true }]} name="handlerName" label="Тип обработчика">
                    <Select
                        items={notificationIntegration?.[INTEGRATION_FIELDS.handlerNames]?.map((handlerNameItem) => {
                            const typedHandlerName: enumHandlerNameShopNotification = handlerNameItem as any;
                            return {
                                id: handlerNameItem,
                                title: localeHandlerName[typedHandlerName],
                            };
                        })}
                        onChange={(formHandlerName: string) => {
                            setHandlerName(formHandlerName);
                        }}
                    />
                </Form.Item>
                <Form.Item {...formItemLayout} rules={[{ required: true }]} name="event" label="Событие">
                    <Select
                        items={Object.values(enumEventShopNotification)?.map((event) => ({
                            id: event,
                            title: localeEventShopNotification[event],
                        }))}
                    />
                </Form.Item>
                <Form.Item name="shopId" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Space>
                        <Button disabled={isSubmitting} loading={isSubmitting} type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                        {error && <ErrorMessage error={error} />}
                    </Space>
                </Form.Item>
            </Form>
        );
    return null;
};

export default ShopNotificationEdit;
