export enum SHOP_FIELDS {
    description = 'description',
    lat = 'lat',
    lon = 'lon',
    alias = 'alias',
    categoryId = 'categoryId',
    defaultPriceId = 'defaultPriceId',
    deliveryInfo = 'deliveryInfo',
    id = 'id',
    imageId = 'imageId',
    ogImageId = 'ogImageId',
    imagePath = 'imagePath',
    ogImagePath = 'ogImagePath',
    jivositeId = 'jivositeId',
    managers = 'managers',
    minOrderSum = 'minOrderSum',
    mode = 'mode',
    notificationEmail = 'notificationEmail',
    priceMode = 'priceMode',
    seoDescription = 'seoDescription',
    seoKeywords = 'seoKeywords',
    seoTitle = 'seoTitle',
    stockMode = 'stockMode',
    title = 'title',
    type = 'type',
    yandexMetrikaId = 'yandexMetrikaId',
    shopUrl = 'shopUrl',
    modelIds = 'modelIds',
    modelId = 'modelId',
    manualProperties = 'manualProperties',
    brandId = 'brandId',
    address = 'address',
    hours = 'hours',
}

export enum SHOP_TYPE {
    SHOP = 'shop',
    MARKETPLACE = 'marketplace',
    EXTERNAL = 'external',
    OFFLINE = 'offline',
    BRAND = 'brand',
    MINI_APP = 'mini-app',
}

export const SHOP_TYPE_LABEL: Record<SHOP_TYPE, string> = {
    [SHOP_TYPE.SHOP]: 'Магазин',
    [SHOP_TYPE.MARKETPLACE]: 'Маркетплейс',
    [SHOP_TYPE.EXTERNAL]: 'Внешний',
    [SHOP_TYPE.OFFLINE]: 'Оффлайн',
    [SHOP_TYPE.BRAND]: 'Бренд',
    [SHOP_TYPE.MINI_APP]: 'Мини-приложение',
};

export enum WEEK_DAYS {
    mon = 'mon',
    tue = 'tue',
    wed = 'wed',
    thu = 'thu',
    fri = 'fri',
    sat = 'sat',
    sun = 'sun',
}

export const DAY_WEEK_LABEL: Record<WEEK_DAYS, { full: string; short: string }> = {
    mon: {
        short: 'Пн',
        full: 'Понедельник',
    },
    tue: {
        short: 'Вт',
        full: 'Вторник',
    },
    wed: {
        short: 'Ср',
        full: 'Среда',
    },
    thu: {
        short: 'Чт',
        full: 'Четверг',
    },
    fri: {
        short: 'Пт',
        full: 'Пятница',
    },
    sat: {
        short: 'Сб',
        full: 'Суббота',
    },
    sun: {
        short: 'Вс',
        full: 'Воскресенье',
    },
};

export type TWeekDay = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export type TWorkHours = {
    [WEEK_DAYS.mon]: [string, string][];
    [WEEK_DAYS.tue]: [string, string][];
    [WEEK_DAYS.wed]: [string, string][];
    [WEEK_DAYS.thu]: [string, string][];
    [WEEK_DAYS.fri]: [string, string][];
    [WEEK_DAYS.sat]: [string, string][];
    [WEEK_DAYS.sun]: [string, string][];
};

export type TPointWorkTime = {
    startPointDay: TWeekDay;
    endPointDay: TWeekDay;
    time: [string, string];
};

export interface IShop {
    [SHOP_FIELDS.modelId]?: number;
    [SHOP_FIELDS.modelIds]?: number[];
    [SHOP_FIELDS.manualProperties]?: { [key: string]: string };
    [SHOP_FIELDS.description]?: string;
    [SHOP_FIELDS.categoryId]: number;
    [SHOP_FIELDS.defaultPriceId]: number | null;
    [SHOP_FIELDS.deliveryInfo]: string;
    [SHOP_FIELDS.id]: number;
    [SHOP_FIELDS.imageId]: number;
    [SHOP_FIELDS.ogImageId]: number;
    [SHOP_FIELDS.imagePath]: string;
    [SHOP_FIELDS.ogImagePath]: string;
    [SHOP_FIELDS.jivositeId]: string;
    [SHOP_FIELDS.managers]: string[];
    [SHOP_FIELDS.minOrderSum]: number;
    [SHOP_FIELDS.mode]: string;
    [SHOP_FIELDS.notificationEmail]: string;
    [SHOP_FIELDS.priceMode]: string;
    [SHOP_FIELDS.seoDescription]: string;
    [SHOP_FIELDS.seoTitle]: string;
    [SHOP_FIELDS.stockMode]: string;
    [SHOP_FIELDS.title]: string;
    [SHOP_FIELDS.lat]?: number;
    [SHOP_FIELDS.lon]?: number;
    [SHOP_FIELDS.brandId]?: number;
    [SHOP_FIELDS.address]?: string;
    [SHOP_FIELDS.type]: SHOP_TYPE;
    [SHOP_FIELDS.yandexMetrikaId]: number;
    [SHOP_FIELDS.hours]?: TWorkHours;
    imageIds?: Array<number>;
}
