import React from 'react';
import 'moment/locale/ru';
import { Checkbox, Form, Input } from 'antd';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { formItemLayout } from '../../../utils/constants';

const IntegrationFields: ({ fieldName, infoFields }: { fieldName: any; infoFields: any }) => JSX.Element[] | null = ({
    fieldName,
    infoFields,
}): JSX.Element[] | null => {
    const renderInput = (itemName: string) => {
        const type = infoFields && infoFields.properties[itemName] ? infoFields.properties[itemName]['type'] : null;
        switch (type) {
            case 'boolean':
                return <Checkbox />;
            default:
                return <Input />;
        }
    };
    const normalizeValue = (value: string, type: string) => {
        switch (type) {
            case 'boolean':
                return value;
            case 'number':
            case 'integer':
                return !value ? null : Number(value);
            case 'string':
            default:
                return value;
        }
    };

    const renderItem = (itemName: string) => {
        const rules = [];
        if (infoFields.required && infoFields.required.indexOf(itemName) !== false) {
            rules.push({ required: true });
        }
        const type = infoFields && infoFields.properties[itemName] ? infoFields.properties[itemName]['type'] : null;
        const additionalProperties = type === 'boolean' ? { valuePropName: 'checked' } : {};

        const formItemProps = {
            ...formItemLayout,
            ...additionalProperties,
            key: itemName,
            name: [fieldName, itemName],
            label: t(itemName as enumFieldName),
            rules,
            normalize: (value: string) => normalizeValue(value, type),
        };

        return <Form.Item {...formItemProps}>{renderInput(itemName)}</Form.Item>;
    };

    if (!infoFields) {
        return null;
    }

    return Object.keys(infoFields.properties).map((itemName) => renderItem(itemName));
};

export default IntegrationFields;
